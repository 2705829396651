<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>登記預約中心</b-breadcrumb-item>
            <b-breadcrumb-item active>預約日曆</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card title="預約日曆">
      <b-overlay :show="showLoading" rounded="sm">
        <b-row no-gutters class="mt-2 mb-4">
          <b-col sm="4" lg="3">
            <b-form-select v-model="query.preset_id" :options="bookingPresetsOptions"/>
          </b-col>
          <b-col sm="4" lg="3">
            <b-select
              class="mr-3"
              :options="statusOptions"
              v-model="query.status"
              :disabled="showLoading || !this.query.preset_id"
            ></b-select>
          </b-col>
          <b-col sm="4" lg="3">
            <b-select
              v-if="showBranchSelect"
              :options="branchOptions"
              v-model="query.branch_id"
              :disabled="showLoading || !this.checkPermission([consts.BOOKING_LIST_BRANCH])"
            ></b-select>
            <b-select
              v-if="showStaffSelect && staffOptions.length > 0"
              class="mr-3"
              :options="staffOptions"
              v-model="query.staff_id"
              :disabled="showLoading"
            ></b-select>
          </b-col>
          <b-col sm="4" lg="3">
            <b-select
              v-if="showServiceSelect"
              class="mr-3"
              :options="serviceOptions"
              v-model="query.service_id"
              :disabled="showLoading"
            ></b-select>
            <h6 class="text-center mt-2">預約筆數：{{ fetchCount }} / 總筆數：{{ totalCount }}</h6>
          </b-col>
        </b-row>
        <FullCalendar :options="calendarOptions" ref="fullCalendar">
          <template v-slot:eventContent="arg">
            <span class="text-center">{{ arg.event.extendedProps.eventContent }}</span>
            <div :id="`tooltip_${arg.event.extendedProps.id}`" class="floating-tooltip" role="tooltip">
              <div class="popover" :class="`bs-popover-${placement}`">
                <h3 class="popover-header">{{ arg.event.extendedProps.eventContent }}</h3>
                <div class="popover-body">
                  <div v-for="field in bookingPresetFields" :key="field.key">
                    <span>{{ field.label }}</span>
                    <span>：</span>
                    <template v-if="arg.event.extendedProps">

                      <span>{{
                        isObject(arg.event.extendedProps[field.key])
                          ? arg.event.extendedProps[field.key].text :
                          arg.event.extendedProps[field.key]
                      }}</span>
                    </template>
                  </div>
                  <router-link :to="{
                    name: 'BookingCenterBookingEdit',
                    params: {
                      id: query.preset_id,
                      booking_id: arg.event.extendedProps.id
                    }
                  }"><span @click="() => closeTooltip()">查看明細</span></router-link>
                </div>
                <span :id="`arrow_${arg.event.extendedProps.id}`" class="arrow"></span>
              </div>
            </div>
          </template>
        </FullCalendar>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import bookingApi from '@/apis/booking'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import zhTwLocale from '@fullcalendar/core/locales/zh-tw'
import collectionApi from "@/apis/collection"
import { arrow, autoPlacement, computePosition, flip, offset, shift } from '@floating-ui/dom'
import Collection from "@/models/Collection.model.js";
import { mapGetters } from "vuex";
import * as consts from "@/consts";
import PermissionChecker from '@/utils/PermissionChecker';
import branchApi from "@/apis/branch";
import { updateQueryFromRoute, updateUrlWithoutReload } from "@/utils/updateUrl";

function clickOutside(element, callback) {
  function handleClick(event) {
    if (!element.contains(event.target)) {
      callback(event)
      document.removeEventListener('click', handleClick)
    }
  }
  document.addEventListener('click', handleClick)
}

export default {
  components: {
    FullCalendar
  },
  data() {
    return {
      consts: consts,
      showLoading: false,
      bookingPresets: [],
      collections: [],
      bookings: null,
      bookingPresetFields: [],
      placement: 'top',
      staffOptions: [],
      statusOptions: [],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin
        ],
        datesSet: this.handleDatesSet,
        locales: [zhTwLocale],
        locale: 'zh-tw',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        firstDay: 1,
        initialView: 'dayGridMonth',
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        events: [],
        eventClick: (arg) => {
          const button = arg.el
          const tooltip = document.querySelector(`#tooltip_${arg.event.extendedProps.id}`)
          this.currentTooltip = tooltip
          const arrowEl = document.querySelector(`#arrow_${arg.event.extendedProps.id}`)

          tooltip.style.display = 'block'
          document.body.appendChild(tooltip)

          computePosition(button, tooltip, {
            placement: this.placement,
            middleware: [
              offset(6),
              flip(),
              shift(),
              autoPlacement({
                alignment: 'start',
                allowedPlacements: ['top', 'bottom'],
              }),
              arrow({ element: arrowEl, padding: 8 })],
            strategy: 'fixed',
          }).then(({ x, y, middlewareData }) => {
            Object.assign(tooltip.style, { left: `${x}px`, top: `${y}px` })

            const { x: arrowX, y: arrowY } = middlewareData.arrow
            Object.assign(arrowEl.style, {
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
              right: '',
              bottom: '',
            })
            setTimeout(() => {
              clickOutside(tooltip, () => {
                tooltip.style.display = 'none'
              })
            })
          })
        },
      },
      currentTooltip: null,
      query: {
        preset_id: null,
        service_id: null,
        branch_id: null,
        staff_id: null,
        status: null,
        start_at: null,
        end_at: null,
      },
      fetchCount: 0,
      totalCount: 0,
    }
  },
  computed: {
    ...mapGetters('general', [
      'myBranches',
      'currentBranch'
    ]),
    queryWatcher() {
      return `${this.query.preset_id}-${this.query.status}-${this.query.service_id}-${this.query.branch_id}-${this.query.staff_id}`;
    },
    currentBookingPreset() {
      return this.bookingPresets.find(booking => booking.id === this.query.preset_id)
    },
    bookingPresetsOptions() {
      return [{ text: '請選擇', value: null, }, ...this.bookingPresets.map(booking => ({
        text: booking.name,
        value: booking.id,
      }))]
    },
    showBranchSelect() {
      return this.currentBookingPreset && this.currentBookingPreset?.config?.branch?.field_id
    },
    showStaffSelect() {
      return this.currentBookingPreset && this.currentBookingPreset?.type == 'wizard' && this.currentBookingPreset?.config?.enabled_selected_staff && this.query.branch_id
    },
    showServiceSelect() {
      return this.currentBookingPreset && this.currentBookingPreset?.type == 'wizard' && this.currentBookingPreset.config?.service?.collection_id
    },
    serviceOptions() {
      const collections = this.collections.find((col) => col.id == this.currentBookingPreset.config?.service?.collection_id);
      const fieldId = this.currentBookingPreset.config?.service?.title_item_id
      const options = collections?.items?.map((item) => {
        let field = item.data.find(d => d.field_id == fieldId)
        return {
          value: item.id,
          text: field.value
        }
      }) ?? []
      return [
        {
          value: null,
          text: "選擇服務"
        },
        ...options
      ]
    },
    branchOptions() {
      let options = this.myBranches
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          return {
            value: branch.id,
            text: branchName
          }
        })
      options = [
        {
            value: null,
            text: '請選擇分店'
        },
        ...options
      ]
      return options
    },
  },
  async mounted() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    if (this.currentBranch) {
      this.query.branch_id = this.currentBranch.id
    }
    await this.fetchBookingPresets()
    if (!this.showBranchSelect) {
      delete this.query.branch_id
      this.fetchBookings();
      this.getStatusOptions();
    }
  },
  watch: {
    queryWatcher: {
      handler() {
        updateUrlWithoutReload(this.query, this.$store, this.$route)
        if (this.query.preset_id) {
          this.fetchBookings();
          this.getStatusOptions();
        } else {
          this.bookings = []
          this.statusOptions = [{
              value: null,
              text: '請選擇狀態'
          }]
        }
      },
    },
    "query.branch_id": {
      async handler(val) {
        if (val && this.query.preset_id) {
          await this.fetchBookings()
          await this.fetchStaff();
        } else {
          this.staffOptions = []
        }
      },
    },
    currentBookingPreset: {
      async handler(bookingPreset) {
        if (!bookingPreset) {
          this.calendarOptions.events = []
          this.bookingPresetFields = []
          return
        }
        this.bookingPresetFields = await this.fetchBookingPresetFields(bookingPreset)
        await this.calculateBookings(this.bookings)
        this.updateCalenderOptions()
      },
      immediate: true
    },
  },
  methods: {
    async handleDatesSet(dateInfo) {
      this.query.start_at = dateInfo.startStr
      this.query.end_at = dateInfo.endStr
      if (this.query.preset_id) {
        await this.fetchBookings()
      }
    },
    async getStatusOptions() {
      const { data } = await bookingApi.getBookingOptions(this.query.preset_id)
      const options = data.status_mapping.map(status => {
        return {
          value: status,
          text: status
        }
      })
      this.statusOptions = [
        {
            value: null,
            text: '請選擇狀態'
        },
        ...options
      ]
    },
    async fetchStaff() {
      const { data } = await branchApi.getBranchStaffs(this.query.branch_id)
      const options = data.data.map(staff => {
        return {
          value: staff.id,
          text: staff.name
        }
      })
      this.staffOptions = [
        {
            value: null,
            text: '請選擇員工'
        },
        ...options
      ]
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async fetchBookingPresets() {
      try {
        this.showLoading = true
        const response = await bookingApi.getBookingPresets({
          page: 1,
          per_page: 20,
          status: "published"
        });
        this.bookingPresets = response.data.data
        this.query.preset_id = this.query.preset_id ?? this.bookingPresets[0]?.id
      } catch (error) {
        console.error(error)
        this.$swal("錯誤", "讀取資料集項目錯誤", "error")
      } finally {
        this.showLoading = false
      }
    },
    async fetchBookings() {
      try {
        this.showLoading = true
        const response = await bookingApi.getBookings(this.query.preset_id, {
          start_at: this.query.start_at,
          end_at: this.query.end_at,
          page: 1,
          per_page: 100,
          keyword: '',
          branch_id: this.query.branch_id,
          staff_id: this.query.staff_id,
          service_id: this.query.service_id,
          status: this.query.status,
        })
        this.fetchCount = response.data.meta.to ?? 0
        this.totalCount = response.data.meta.total ?? 0
        await this.calculateBookings(response.data.data)
        this.updateCalenderOptions()
      }
      catch (error) {
        console.error(error)
        this.$swal("錯誤", "讀取資料集項目錯誤", "error")
      } finally {
        this.showLoading = false
      }
    },
    updateCalenderOptions() {
      this.calendarOptions.events = [];
      this.calendarOptions.events = this.bookings?.map(booking => {
        return {
          id: booking.id,
          title: booking.data[0]?.value,
          start: booking.start_at,
          end: booking.end_at,
          extendedProps: { ...booking, eventContent: this.calcEventContent(booking) }
        }
      })
    },
    calcEventContent(booking) {
      const titleId = this.currentBookingPreset?.config?.booking_mapping?.bookings?.title
      const title = booking?.data?.find(field => field.field_id === titleId)?.value
      const customerNameOrId = booking?.customer?.name || booking?.customer_id
      return title || customerNameOrId || booking.id
    },
    closeTooltip() {
      if (this.currentTooltip) {
        this.currentTooltip.style.display = 'none'
      }
    },
    async fetchBookingPresetFields(bookingPreset) {
      let newField = []

      for (let i = 0; i < bookingPreset?.config?.fields.length; i++) {
        const field = bookingPreset?.config?.fields?.[i]

        if (field.config?.list_hidden === true) {
          continue
        }

        let fieldConfig = {
          key: field._id ?? field.config._id,
          label: field.title ?? field.config?.title,
          sortable: true,
        }

        if (["collection_select", "collection_carousel"].includes(field.type) && !this.collections.find((collection) => collection.id === field.config.collection_id)) {
          let collectionResponse = await collectionApi.getCollection(field.config.collection_id);
          this.collections.push(collectionResponse.data.data);
        }
        newField.push(fieldConfig)
      }
      if (this.currentBookingPreset?.type == 'wizard') {
        if (this.currentBookingPreset?.config?.enabled_selected_branch) {
          newField.push({
            key: "branch_name",
            label: "分店",
            sortable: true,
          })
        }

        if (this.currentBookingPreset?.config?.enabled_selected_staff) {
          newField.push({
            key: "staff_name",
            label: "員工",
            sortable: true,
          })
        }

        if (this.currentBookingPreset.config?.service?.collection_id) {
          if (!this.collections.find((collection) => collection.id === this.currentBookingPreset.config?.service?.collection_id)) {
            let collectionResponse = await collectionApi.getCollection(this.currentBookingPreset.config?.service?.collection_id);
            this.collections.push(collectionResponse.data.data);

            newField.push({
              key: 'service_name',
              label: '服務',
              sortable: true,
            })
          }
        }
      }
      return newField
    },
    async calculateBookings(bookings) {
      if (!bookings) {
        return
      }
      this.bookings = bookings.map(booking => {
        let newBooking = { ...booking }
        for (let i = 0; i < booking.data.length; i++) {
          let data = booking.data[i]
          // 如果遇到陣列型的資料，將陣列轉為字串
          if (Array.isArray(data.value)) {
            if (typeof data.value[0] === 'object') {
              if (data.value[0] !== null && ['collection_select', 'collection_carousel'].includes(data.value[0].type)) {
                for (let j = 0; j < data.value.length; j++) {
                  console.log(data.value[j])
                  const collection = this.collections.find((collection) => collection.id === data.value[j].collection_id);
                  if (!collection) {
                    continue;
                  }
                  const collectionModel = new Collection(collection)
                  data.value[j].text = collectionModel.textForOption(data.value[j].collection_item_id)
                }
                newBooking[data.field_id] = data.value.map((item) => item.text).join(', ');
                data.value = data.value.map((item) => item.text).join(', ');
                continue;
              }
            } else {
              newBooking[data.field_id] = data.value.join(', ');
              continue;
            }
          }

          if (typeof data.value === 'object' && data.value !== null && ['collection_select', 'collection_carousel'].includes(data.value.type)) {
            const collection = this.collections.find((collection) => collection.id === data.value.collection_id);

            if (!collection) {
              continue
            }

            const collectionModel = new Collection(collection)
            data.value.text = collectionModel.textForOption(data.value.collection_item_id)
            data.value = data.value.text
          }
          newBooking[data.field_id] = data.value
        }
        if (this.currentBookingPreset?.type == 'wizard') {
          if (this.currentBookingPreset?.config?.enabled_selected_branch) {
            newBooking['branch_name'] = booking.branch?.name || booking.branch_id
          }

          if (this.currentBookingPreset?.config?.enabled_selected_staff) {
            newBooking['staff_name'] = booking.staff?.name || booking.staff_id
          }

          if (this.currentBookingPreset?.config?.service?.collection_id) {
            const collection = this.collections.find((collection) => collection.id === this.currentBookingPreset?.config?.service?.collection_id);
            let serviceName = ""
            if (collection && booking.service_id) {
              const fieldId = this.currentBookingPreset.config?.service?.title_item_id
              const items = collection.items?.filter((item) => booking.service_id?.includes(item.id));
              if (items) {
                serviceName =  items.map((item) => {
                  return item.data.find((data) => data.field_id == fieldId).value;
                }).join(', ');
              }
            }

            newBooking['service_name'] = serviceName
          }
        }
        return newBooking
      })
    },
    isObject(field) {
      return typeof field === 'object' && field !== null;
    },
  }
}
</script>

<style scoped lang="scss">
.floating-tooltip {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: max-content;
  padding: 5px;
  font-weight: bold;
  border-radius: 4px;
  font-size: 90%;
  z-index: 10000;

  .popover {
    position: relative;
  }
}

.row-searchbar {
  width: 80%;

  @media screen and (max-width: 768px) {
  width: 100%;
  }
}
</style>
